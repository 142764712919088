<template>
  <div
      class="relative flex items-center w-full border border-#CBCBCB px-4 py-3.5 rounded-3 text-14px leading-6 font-medium text-bw-32"
  >
    <div
        ref="modelRef"
        @click.stop="openList = !openList"
        class="flex items-center justify-between w-full cursor-pointer"
    >
      <div class="duration-400">
        <span class="" v-for="(item, index) in data" v-html="indexActive == index ? item.content : ''"> </span>
      </div>
      <span class="text-#B3b3b3 w-5.5 h-5.5 i-custom-chevron-down"> </span>
    </div>

    <ul
        class="absolute top-full left-0 right-0 w-full border border-#cbcbcb rounded-2 overflow-hidden mt-2 flex flex-col max-h-50 scrollEvent scroll-smooth overflow-y-scroll z-20 bg-white"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 1px 0px"
        v-show="openList"
    >
      <template v-for="(item, index) in data">
        <li
            class="p-2 hover:bg-primary duration-400 cursor-pointer hover:text-white"
            :class="indexActive == index ? 'bg-primary text-white' : 'bg-tranparent'"
            @click=";[(indexActive = index), handleClick(item?.id)]"
        >
          <div class="capitalize truncate" v-html="item?.content"></div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  data: {
    type: Array as any
  },
  idQuestion: {
    type: Number
  }
})

const emits = defineEmits(['update:senData'])
const indexActive = ref<number | any>(0)
const openList = ref<boolean | any>(false)
const modelRef = ref<HTMLElement | any>(null)

onClickOutside(modelRef, () => {
  openList.value = false
})

const handleClick = (id: any) => {
  if (props.idQuestion) {
    emits('update:senData', props.idQuestion + '_' + id)
  } else {
    emits('update:senData', id)
  }
  openList.value = false
}

onMounted(() => {
  for (let i = 0; i < props.data.length; i++) {
    if (props.idQuestion) {
      emits('update:senData', props.idQuestion + '_' + props.data[0]?.id)
    } else {
      emits('update:senData', props.data[0]?.id)
    }
  }
})
</script>
